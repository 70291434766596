<template>
    <r-e-dialog :title="title" :visible.sync="dialogVisible" show-footer top="5vh" width="700px"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="125px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="房源地址">
                    <el-input type="text" v-model="formPublish.apartmentName" style="width: 400px;" placeholder="请输入内容"
                              disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="资产名称">
                    <el-input type="text" v-model="formPublish.asstesName" style="width: 400px;" placeholder="请输入内容"
                              disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="内容" prop="content">
                    <el-input type="textarea" v-model="formPublish.content" :rows="2" style="width: 400px;"
                              placeholder="请输入内容"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="维修说明">
                    <el-input type="textarea" v-model="formPublish.repairExplain" :rows="2" style="width: 400px;"
                              placeholder="请输入维修说明"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="照片附件" class="samplePhotos">
                    <upload-picture-card :uuidList="photos" :limit="24" @on-success="handleSuccess"
                                         @on-remove="handleRemove"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import {MessageSuccess, MessageError} from "@custom/message";
import {report} from "@/api/assets";

export default {
    name: "dialog-repair",
    data() {
        return {
            dialogVisible: false,
            title: null,
            formPublish: {
                apartmentName: null,
                asstesName: null,
                content: null,
                repairExplain: null,
                apartmentUuid: null,
                uuid: null,
                type: null
            },
            rules: {
                content: [{required: true, message: '请输入内容', trigger: 'blur'}]
            },
            photos: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    components: {uploadPictureCard},
    methods: {
        //资产上报状态弹窗方法
        openDialog(data, type) {
            //根据type判断上报状态类型
            switch (type) {
                case 700103:
                    this.title = "报修";
                    break;
                case 700104:
                    this.title = "报废";
                    break;
                case 700105:
                    this.title = "维修中";
                    break;
            }
            //解构数据
            let {uuid, apartmentUuid, apartmentName, asstesName} = data;
            //保存数据
            this.formPublish.type = type;
            this.formPublish.uuid = uuid;
            this.formPublish.apartmentUuid = apartmentUuid;
            this.formPublish.apartmentName = apartmentName;
            this.formPublish.asstesName = asstesName;
            //打开资产上报状态弹窗
            this.dialogVisible = true;
        },

        // 点击弹框确认按钮事件
        clickSubmit() {
            const that = this;
            //表单校验
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading(loadingOptions);
                    //解构数据
                    const {content, repairExplain, apartmentUuid, uuid, type} = that.formPublish;
                    //拼接图片
                    const photos = that.photos.join(";");
                    //打包数据
                    const data = {content, repairExplain, apartmentUuid, uuid, type, photos}
                    //调用接口上报状态
                    report(data).then(res => {
                        MessageSuccess(that.title + '成功');
                        //调用弹框取消按钮事件，关闭弹窗
                        that.clickCancel();
                        loading.close();
                    }).catch(err => {
                        MessageError(that.title + '失败');
                        loading.close();
                    });
                }
            });
        },

        // 点击弹框取消按钮事件
        clickCancel() {
            //清空表单数据
            this.$refs["formPublish"].resetFields();
            //关闭弹窗
            this.dialogVisible = false;
        },

        //图片上传成功事件
        handleSuccess({fileList}) {
            //获取全部上传成功图片uuid
            this.photos = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else {
                    return name;
                }
            });
        },

        //图片删除成功回调
        handleRemove({fileList}) {
            //获取全部剩余图片uuid
            this.photos = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else {
                    return name;
                }
            });
        },
    },
}
</script>

<style scoped>

</style>